import {analytics} from '@verily-src/analytics';
import '@verily-src/phaf-runtime-helpers';
import {
  Bundle,
  BundleStep,
  ButtonState,
  InfoStaticContent,
  StepStatus,
  StepType,
} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/bundle_service';
import {LogErrorRequest_Severity} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/logger_service';
import {BundleStatus, pollListBundles} from './bundle-utils';
import BundleService, {
  getBundleStatusesFromCache,
} from './service/BundleService';
import LoggingService from './service/LoggingService';
import {UrlChangeObserver} from './url-change-observer';
import {
  getFromUrl,
  getInternalUrl,
  goToPage,
  setBundleStepUrl,
  UrlStepType,
} from './url-utils';

/**
 * @deprecated Use the BundleUtility class instead of individually exported functions
 * Navigates to the specified step in a bundle.
 *
 * Start can be called from the Verily Me Web home cards, or from email deep links.
 * It fetches the bundle and triggers navigation to wherever the participant left off.
 * That's usually the first step in the bundle unless the participant is returning
 * to an in-progress bundle.
 */
export async function start(bundleId: string): Promise<void> {
  const {steps} = await BundleService.getBundle(bundleId);
  // Find the first non-complete step
  const step = steps.find(step => step.status !== StepStatus.COMPLETED);
  // No non-complete steps
  if (!step) {
    // Navigate to home if all steps are completed
    goToPage('/me/home', 'task-done');
    return Promise.resolve();
  }
  // Set the step to be in progress
  step.status = StepStatus.IN_PROGRESS;
  // Update bundle steps in the backend
  BundleService.updateBundleSteps(bundleId, [step]);

  // Tracking with Pendo
  const analyticsMap: Map<string, string> = new Map();
  analyticsMap.set('bundle-id', bundleId);
  analytics.track('start-bundle', analyticsMap);

  // Navigate to the step
  navigateToStep(bundleId, step);

  return Promise.resolve();
}

const navigateToStep = (bundleId: string, step: BundleStep): void => {
  setBundleStepUrl({
    bundleId,
    stepId: step.name,
    stepType: convertBundleStepTypeToString(step.type),
    isRedirect: true,
  });
};

/**
 * Converts a StepType enum value to its corresponding string representation.
 * @param {StepType} stepType - The StepType enum value to convert.
 * @returns {UrlStepType} The string representation of the StepType.
 * @throws {Error} If the step type is invalid.
 */
export function convertBundleStepTypeToString(stepType: StepType): UrlStepType {
  switch (stepType) {
    case StepType.UNSPECIFIED:
      return 'unspecified';
    case StepType.SURVEY:
      return 'survey';
    case StepType.CONSENT:
      return 'consent';
    case StepType.CONTENT:
      return 'info';
    case StepType.ID_VERIFICATION:
      return 'idv';
    case StepType.ENROLLMENT:
      return 'enrollment';
    default:
      throw new Error('Invalid step type');
  }
}

/**
 * @deprecated Use the BundleUtility class instead of individually exported functions
 * Navigates to the next step in a bundle.
 *
 * Advance is called by an MFE when it's ready to complete the step and move on.
 * This is generally from a standard button marked "Next" or "Done". Like Start,
 * it triggers navigation to the next step. Unlike Start, it doesn't take the
 * bundleId as an argument, because the Bundle MFE extracts them from the current
 * URL. NextBundleStepParams includes any information needed from the current step
 * to identify which step to go to next. It's currently empty, but is included to
 * support branching in the future.
 */
export async function advance(
  params?: NextBundleStepParameters
): Promise<void> {
  const bundleId = getFromUrl('bundle');
  const stepId = getFromUrl('step');

  if (!bundleId || !stepId) {
    throw new Error('Bundle MFE: stepId and bundleId are required.');
  }

  const {steps, redirectUrlOnCompletion} = await BundleService.getBundle(
    bundleId
  );
  const idx = steps.findIndex(step => step.name === stepId);

  if (idx === -1) {
    throw new Error(
      `Bundle MFE: advance called with unknown stepId: ${stepId}`
    );
  }

  // Update this step to be complete
  const step = steps[idx];
  step.status = StepStatus.COMPLETED;

  // Setup for Pendo analytics tracking
  const analyticsMap: Map<string, string> = new Map();
  analyticsMap.set('bundle-id', bundleId);
  analyticsMap.set('step-id', stepId);

  if (params) {
    try {
      Object.entries(params).forEach(([key, value]) => {
        analyticsMap.set(key, JSON.stringify(value));
      });
    } catch (error) {
      console.error('Error stringifying freeform params for Pendo', error);
    }
  }

  // Last step
  if (idx === steps.length - 1) {
    // Tracking with Pendo
    analyticsMap.set('is-final-step', 'true');
    analytics.track('advance-bundle-step', analyticsMap);

    if (redirectUrlOnCompletion.length) {
      // Need to redirect to a different URL upon completion
      // Why the following measures: go/vmw-redirect-infinite-loop-bug
      const internalUrl = getInternalUrl(redirectUrlOnCompletion);
      // Synchronously update bundle steps in the backend before redirecting
      await BundleService.updateBundleSteps(bundleId, [step]);
      if (internalUrl) {
        // For internal URLs, use navigateToUrl to avoid a full page reload
        goToPage(internalUrl);
      } else {
        window.location.href = redirectUrlOnCompletion;
      }
    } else {
      // Asynchronously update bundle steps in the backend
      BundleService.updateBundleSteps(bundleId, [step]);
      goToPage();
    }
    return Promise.resolve();
  } else {
    // Tracking with Pendo
    analyticsMap.set('is-final-step', 'true');
    analytics.track('advance-bundle-step', analyticsMap);

    // There exists a next step
    // Update next step to be in progress
    const nextStep = steps[idx + 1];
    nextStep.status = StepStatus.IN_PROGRESS;
    // Update bundle steps in the backend
    BundleService.updateBundleSteps(bundleId, [step, nextStep]);
    // Navigate to the next step
    setBundleStepUrl({
      bundleId: bundleId,
      stepId: nextStep.name,
      stepType: convertBundleStepTypeToString(nextStep.type),
      isRedirect: false,
    });
    return Promise.resolve();
  }
}

/**
 * @deprecated Use the BundleUtility class instead of individually exported functions
 * @param _params Parameters for the next bundle step
 * @returns An empty promise when complete
 */
export async function back(_params?: NextBundleStepParameters): Promise<void> {
  const bundleId = getFromUrl('bundle');
  const stepId = getFromUrl('step');

  if (!bundleId || !stepId) {
    throw new Error('Bundle MFE: stepId and bundleId are required.');
  }

  const {steps} = await BundleService.getBundle(bundleId);
  const idx = steps.findIndex(step => step.name === stepId);
  if (idx < 0) {
    throw new Error(`Bundle MFE: back called with unknown stepId: ${stepId}`);
  }

  // Setup for tracking with Pendo
  const analyticsMap: Map<string, string> = new Map();
  analyticsMap.set('bundle-id', bundleId);
  analyticsMap.set('step-id', stepId);

  if (idx === 0) {
    // Tracking with Pendo
    analyticsMap.set('is-first-step', 'true');
    analytics.track('back-bundle-step', analyticsMap);

    goToPage();
    return Promise.resolve();
  }

  // Tracking with Pendo
  analyticsMap.set('is-first-step', 'false');
  analytics.track('back-bundle-step', analyticsMap);

  // Navigate to the previous step
  const nextStep = steps[idx - 1];
  setBundleStepUrl({
    bundleId: bundleId,
    stepId: nextStep.name,
    stepType: convertBundleStepTypeToString(nextStep.type),
    isRedirect: false,
  });
  return Promise.resolve();
}

/**
 * @deprecated Use the BundleUtility class instead of individually exported functions
 * @returns Empty promsie when finished
 */
export async function exit(): Promise<void> {
  // Tracking with Pendo
  analytics.track('exit-bundle');

  goToPage();
  return Promise.resolve();
}

/**
 * @deprecated Use the BundleUtility class instead of individually exported functions
 * Returns details about the current step beyond what's in the URL.
 *
 * Returns details about the current step; namely, the fields:
 * id, backButtonState, nextButtonLabel, which are used for
 * rendering the back and next buttons at the end of the page.
 */
export async function getCurrentStep(): Promise<Step> {
  const bundleId = getFromUrl('bundle');
  const stepId = getFromUrl('step');

  if (!bundleId || !stepId) {
    throw new Error('Bundle MFE: stepId and bundleId are required.');
  }

  const {bundlePlanId, bundlePlanName, steps} = await BundleService.getBundle(
    bundleId
  );
  const step = steps.find(step => step.name === stepId);
  if (!step) {
    throw new Error(
      `Bundle MFE: could not get current step for ${bundleId} ${stepId}`
    );
  }
  return getStepConfig(step, stepId, bundlePlanId, bundlePlanName);
}

/**
 * Completes the current step and navigates to home.
 * Note: This function should only be used if the current step is *submittable*.
 * @returns Empty promise when complete
 */
async function complete(): Promise<void> {
  const bundleId = getFromUrl('bundle');
  const stepId = getFromUrl('step');

  if (!bundleId || !stepId) {
    throw new Error('Bundle MFE: stepId and bundleId are required.');
  }

  const {steps} = await BundleService.getBundle(bundleId);
  const idx = steps.findIndex(step => step.name === stepId);

  if (idx === -1) {
    throw new Error(
      `Bundle MFE: complete called with unknown stepId: ${stepId}`
    );
  }

  // Update this step to be complete
  const step = steps[idx];
  step.status = StepStatus.COMPLETED;

  // Setup for Pendo analytics tracking
  const analyticsMap: Map<string, string> = new Map();
  analyticsMap.set('bundle-id', bundleId);
  analyticsMap.set('step-id', stepId);
  analytics.track('complete-bundle-step', analyticsMap);

  // Asynchronously update bundle steps in the backend
  BundleService.updateBundleSteps(bundleId, [step]);
  goToPage();

  return Promise.resolve();
}

function getUpdatedBundleStatuses(): Record<string, BundleStatus> {
  return getBundleStatusesFromCache();
}

function batchGetBundles(bundleNames: string[]): Promise<Bundle[]> {
  return BundleService.batchGetBundles(bundleNames);
}

function getStepConfig(
  step: BundleStep,
  stepId?: string,
  bundlePlanId?: string,
  bundlePlanName?: string
): Promise<Step> {
  if (step) {
    let backButtonLabel: string | undefined,
      backButtonState: 'enabled' | 'disabled' | 'hidden' | undefined,
      closeButtonLabel: string | undefined,
      nextButtonLabel: string | undefined;

    if (step.staticContent.oneofKind === 'infoStaticContent') {
      const staticContent = step.staticContent as {
        oneofKind: 'infoStaticContent';
        infoStaticContent: InfoStaticContent;
      };

      switch (staticContent.infoStaticContent.previousButtonState) {
        case ButtonState.HIDDEN:
          backButtonState = 'hidden';
          break;
        case ButtonState.VISIBLE_AND_DISABLED:
          backButtonState = 'disabled';
          break;
        case ButtonState.VISIBLE_AND_ENABLED:
          backButtonState = 'enabled';
          break;
        case ButtonState.BUTTON_STATE_UNSPECIFIED:
          backButtonState = undefined;
          break;
      }
      backButtonLabel = staticContent.infoStaticContent.previousButtonText;
      closeButtonLabel = staticContent.infoStaticContent.closeButtonText;
      nextButtonLabel = staticContent.infoStaticContent.nextButtonText;
    }

    return Promise.resolve({
      id: step.name,
      name: step.name,
      actionName: step.actionName,
      backButtonLabel,
      backButtonState,
      bundlePlanId,
      bundlePlanName,
      closeButtonLabel,
      nextButtonLabel,
    });
  }
  throw new Error(
    `Bundle MFE: getCurrentStep called with unknown stepId${
      stepId && `: ${stepId}`
    }`
  );
}

export {BundleStatus};

export type NextBundleStepParameters = {};

export type Step = {
  id: string;
  name: string; // identical to id - deprecate id for consistency with BundleStep
  actionName: string;
  backButtonState?: 'enabled' | 'disabled' | 'hidden';
  backButtonLabel?: string;
  bundlePlanId?: string;
  bundlePlanName?: string;
  closeButtonLabel?: string;
  nextButtonLabel?: string;
};

function handleBundleStartDeepLinking(
  location: Location
): Promise<void> | undefined {
  const expectedPath = '/me/bundle/start';

  const {pathname} = new URL(location.href);
  if (pathname.trim().toLowerCase() !== expectedPath) {
    return;
  }

  const bundleId = getFromUrl('bundle');
  if (!bundleId) {
    pollListBundles().then(activeBundles => {
      if (activeBundles.length === 1) {
        start(activeBundles[0].name);
      }
    });
    return;
  }

  start(bundleId);
}

function handleDeepLinks(): void {
  const urlChangeObserver = new UrlChangeObserver();
  urlChangeObserver.addHandler(handleBundleStartDeepLinking);
  urlChangeObserver.observe();
}

handleDeepLinks();

export class BundleUtility {
  static async start(bundleId: string): Promise<void> {
    return start(bundleId);
  }

  static async advance(params?: NextBundleStepParameters): Promise<void> {
    return advance(params);
  }

  static async back(params?: NextBundleStepParameters): Promise<void> {
    return back(params);
  }

  static async exit(): Promise<void> {
    return exit();
  }

  static async complete(): Promise<void> {
    return complete();
  }

  static async getCurrentStep(): Promise<Step> {
    return getCurrentStep();
  }

  static getUpdatedBundleStatuses(): Record<string, BundleStatus> {
    return getUpdatedBundleStatuses();
  }

  static batchGetBundles(bundleNames: string[]): Promise<Bundle[]> {
    return batchGetBundles(bundleNames);
  }
}

export class Logger {
  static async logError(
    name: string,
    stack: string,
    url: string,
    componentName: string,
    severity: LogErrorRequest_Severity
  ): Promise<void> {
    return LoggingService.logError(name, stack, url, componentName, severity);
  }
}
